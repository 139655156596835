// Breakpoints

$xs_bp: 468px;
$sm_bp: 576px;
$sp_bp: 768px;
$tb_bp: 992px;
$dt_bp: 1200px;
$bdt_bp: 1330px;

// Breackpoints Use in mixins
$low-mobile-breackpoint: 375px;
$mobile-breackpoint: 1024px;
$tablet-breackpoint: 1310px;

// Colors
$primary: #45c98f;
// $primary: #ac28f5;
$primaryHover: #4aca93;
// $primaryHover: #c55fff;
$secondary: #ccff74;
$success: #8ad52a;
// $btnPrimary: #98c063;
// $btnSecondary: #b1cf8a;
$pink: #ff809e;
$green: #3feb7e;
$dark: #444444;
$text: #555;
$text-primary: #efefef;
$gray: #999;
$white: #fff;
$black: #0d0d0d;
$light-gray: #707378;

$gradient-yellow: linear-gradient(180deg, #ffd770 0%, #ff9f15 100%);
$gradient-pink: linear-gradient(180deg, #fdbd91 0%, #f66daf 100%);
$gradient-green: linear-gradient(180deg, #beec82 0%, #7bc719 100%);

:root {
  --theme-body-color: #262626;
  --theme-footer-color: #242424;
  --theme-card-color: #181a1e;
  --thame-card-shadow: transparent;
  --theme-border-color: #142149;
  --theme-text-primary: #efefef;
  --theme-text-secondary: #b7b7b7;
  --theme-text-gray: #707378;
  --thame-text-light-gray: #efefef;
  --thame-text-dark-gray: #efefef;
  --theme-text-white: #fff;
  --theme-box-shadow: rgba(5, 5, 5, 0.3);
  --theme-modal-bg: #160e24;
  --thame-modal-text-gray: #707378;
  --thame-modal-backing-bg: #181a1e;
  --thame-socials-primary: #fff;
  --thame-socials-gray: #707378;
  --thame-btn-square: #313339;
  --thame-close-icon: rgba(255, 255, 255, 0.6);
  --thame-card-btn: #0d0d0d;
  --thame-card-btn-text: #fff;
  --thame-card-border-color: transparent;
  --thame-card-no-image: #707378;
  --thame-card-bg: #071235;
  --thame-card-no-image-bg: #0c1e52;
  --thame-disabled-btn-text: #343639;
  --theme-avatar-bg: #ac28f5;
  --theme-avatar-text: #fff;
  --theme-nfts-fade-light: rgba(5, 5, 5, 0.1);
  --theme-nfts-fade: rgba(5, 5, 5, 0.95);
  --theme-nfts-fade-footer: #050505;
  --theme-switch-bg: #383838;
  --theme-text-title: #ffffff;
}

.light-mode {
  --theme-body-color: rgb(231, 226, 240);
  --theme-footer-color: #fafafa;
  --theme-card-color: #ffffff;
  --thame-card-shadow: #c5c5c5;
  --theme-border-color: #e8e8e8;
  --theme-text-primary: #000;
  --theme-text-secondary: #555;
  --theme-text-gray: #999;
  --thame-text-light-gray: #999;
  --thame-text-dark-gray: #444;
  --theme-text-white: #000;
  --theme-box-shadow: rgba(197, 197, 197, 0.25);
  --theme-modal-bg: #fff;
  --thame-modal-backing-bg: #f3f3f3;
  --thame-socials-primary: #ac28f5;
  --thame-socials-gray: #ac28f5;
  --thame-btn-square: #999;
  --thame-close-icon: rgba(29, 29, 29, 0.6);
  --thame-card-btn: #fbfbfb;
  --thame-card-btn-text: #555;
  --thame-card-border-color: #e7e7e7;
  --thame-card-bg: #fff;
  --thame-card-no-image: #999;
  --thame-card-no-image-bg: #ededed;
  --thame-disabled-btn-text: #c2c3c5;
  --theme-avatar-bg: #000;
  --theme-avatar-text: #ac28f5;
  --theme-nfts-fade-light: rgba(241, 238, 238, 0.1);
  --theme-nfts-fade: rgba(221, 217, 217, 0.95);
  --theme-nfts-fade-footer: #eee7e7;
  --theme-switch-bg: #eaeaea;
  --theme-text-title: #45c98f;
}

@mixin cardHead {
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  grid-gap: 16px;
  margin-bottom: 19px;

  h4 {
    margin-bottom: 0;
    color: var(--theme-text-primary);

    i {
      font-size: 9px;
      position: relative;
      top: -3px;
    }
  }

  span {
    font-size: 16px;
    line-height: 20px;
    color: #999;

    i {
      position: relative;
      top: 2px;
    }
  }

  img {
    max-width: 100%;
  }
}

// Flex
@mixin flex-align($align-position) {
  display: flex;
  align-items: $align-position;
}

@mixin flex-justify($justify-position) {
  display: flex;
  justify-content: $justify-position;
}

@mixin flex-justify-align($justify-position, $align-position) {
  display: flex;
  justify-content: $justify-position;
  align-items: $align-position;
}

/* Breakpoint mixins */
@mixin media-tablet {
  @media (max-width: $tablet-breackpoint) {
    @content;
  }
}

@mixin media-mobile {
  @media (max-width: $mobile-breackpoint) {
    @content;
  }
}

@mixin media-low-mobile {
  @media (max-width: $low-mobile-breackpoint) {
    @content;
  }
}

.account {
  height: 100%;
  margin-bottom: 65px;
  position: relative;

  &__inner {
    text-align: center;
    max-width: 800px;
    width: 100%;
    margin: 0 auto 50px;
  }

  &__background {
    height: 222px;
    margin-bottom: -70px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__avatar {
    width: 140px;
    height: 140px;
    margin: 0 auto 15px;
    border: 2px solid var(--theme-body-color);
    border-radius: 50%;
    background-color: var(--theme-avatar-bg);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: var(--theme-avatar-text);
      font-size: 40px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
      background-color: var(--theme-body-color);
    }
  }

  &__name {
    @include flex-justify-align(center, center);
    font-weight: 600;
    font-size: 20px;
    line-height: 1;
    color: var(--thame-text-dark-gray);
    margin-bottom: 10px;
  }

  &__description {
    max-width: 550px;
    width: 100%;
    margin: 0 auto 25px;
    font-size: 14px;
    line-height: 21px;
    color: var(--theme-text-secondary);
  }

  &__author {
    font-weight: normal;
    font-size: 16px;
    line-height: 1;
    color: var(--theme-text-gray);
    margin-bottom: 15px;

    span {
      color: $primary;
      margin-left: 6px;
    }
  }

  &__status {
    width: 20px;
    height: 20px;
    min-width: 20px;
    margin-left: 5px;
  }

  @include media-tablet {
    margin-bottom: 45px;
  }

  @include media-mobile {
    margin-bottom: 20px;

    &__inner {
      margin-bottom: 20px;
    }

    &__background {
      height: 190px;
    }

    &__avatar {
      width: 120px;
      height: 120px;
    }

    &__name {
      font-size: 18px;
      margin-bottom: 8px;
    }
  }
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 150px;
  gap: 40px;
  &__title {
    // width: 853px;
    font-family: 'Chillax';
    font-style: normal;
    font-weight: 700;
    font-size: 96px;
    line-height: 120%;
    text-align: center;
    color: var(--theme-text-primary);
    @include media-tablet {
      width: 100%;
      font-size: 76px;
    }
    @include media-mobile {
      font-size: 56px;
    }
  }
  &__subtitle {
    font-family: 'Chillax';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: $primary;
  }
  &__titleText {
    font-family: 'Chillax';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    opacity: 0.7;
  }
  &__line {
    width: 100%;
    height: 0px;
    opacity: 0.5;
    border: 1px solid #ffffff;
  }
  &__descTitle {
    font-family: 'Chillax';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    color: var(--theme-text-primary);
  }
  &__description {
    max-width: 1200px;
    font-family: 'Chillax';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: var(--theme-text-primary);
    opacity: 0.7;
  }
  &__dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #45c98f;
    margin-right: 5px;
  }
  &__highlight {
    font-family: 'Chillax';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: rgba(69, 201, 143, 1);
  }
  &__warning {
    max-width: 980px;
    font-family: 'Chillax';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #45c98f;
    opacity: 0.7;
  }
}

.title {
  width: 486px;
  font-family: 'Chillax';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  margin-bottom: 50px;
  &.qtnTitle {
    margin-bottom: 0;
  }
  @include media-tablet {
    width: 100%;
    font-size: 36px;
  }
  @include media-mobile {
    font-size: 26px;
  }
}

.pluses {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin-bottom: 150px;
  &__card {
    width: 300px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 15px;
    &_img {
      width: 44px;
      height: 32px;
    }
  }
}

.responsibilities {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @include media-tablet {
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
  &__text {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 650px;
    &_title {
      font-family: 'Chillax';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      color: $primary;
    }
    &_description {
      display: flex;
      align-items: center;
      span {
        display: block;
        width: 8px;
        height: 8px;
        background: $primary;
        margin-right: 10px;
        border-radius: 50%;
      }
      p {
        margin: 0;
      }
    }
  }
  &__img {
    max-width: 600px;
    width: 100%;
  }
}

.qtn {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 100px;
  &__warn {
    font-family: 'Chillax';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: $primary;
  }
}
