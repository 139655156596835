@mixin cardHead {
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  grid-gap: 16px;
  margin-bottom: 19px;

  h4 {
    margin-bottom: 0;
    color: var(--theme-text-primary);

    i {
      font-size: 9px;
      position: relative;
      top: -3px;
    }
  }

  span {
    font-size: 16px;
    line-height: 20px;
    color: #999;

    i {
      position: relative;
      top: 2px;
    }
  }

  img {
    max-width: 100%;
  }
}

// Flex
@mixin flex-align($align-position) {
  display: flex;
  align-items: $align-position;
}

@mixin flex-justify($justify-position) {
  display: flex;
  justify-content: $justify-position;
}

@mixin flex-justify-align($justify-position, $align-position) {
  display: flex;
  justify-content: $justify-position;
  align-items: $align-position;
}

/* Breakpoint mixins */
@mixin media-tablet {
  @media (max-width: $tablet-breackpoint) {
    @content;
  }
}

@mixin media-mobile {
  @media (max-width: $mobile-breackpoint) {
    @content;
  }
}

@mixin media-low-mobile {
  @media (max-width: $low-mobile-breackpoint) {
    @content;
  }
}
