// Breakpoints

$xs_bp: 468px;
$sm_bp: 576px;
$sp_bp: 768px;
$tb_bp: 992px;
$dt_bp: 1200px;
$bdt_bp: 1330px;

// Breackpoints Use in mixins
$low-mobile-breackpoint: 375px;
$mobile-breackpoint: 1024px;
$tablet-breackpoint: 1310px;

// Colors
$primary: #45c98f;
// $primary: #ac28f5;
$primaryHover: #4aca93;
// $primaryHover: #c55fff;
$secondary: #ccff74;
$success: #8ad52a;
// $btnPrimary: #98c063;
// $btnSecondary: #b1cf8a;
$pink: #ff809e;
$green: #3feb7e;
$dark: #444444;
$text: #555;
$text-primary: #efefef;
$gray: #999;
$white: #fff;
$black: #0d0d0d;
$light-gray: #707378;

$gradient-yellow: linear-gradient(180deg, #ffd770 0%, #ff9f15 100%);
$gradient-pink: linear-gradient(180deg, #fdbd91 0%, #f66daf 100%);
$gradient-green: linear-gradient(180deg, #beec82 0%, #7bc719 100%);

:root {
  --theme-body-color: #262626;
  --theme-footer-color: #242424;
  --theme-card-color: #181a1e;
  --thame-card-shadow: transparent;
  --theme-border-color: #142149;
  --theme-text-primary: #efefef;
  --theme-text-secondary: #b7b7b7;
  --theme-text-gray: #707378;
  --thame-text-light-gray: #efefef;
  --thame-text-dark-gray: #efefef;
  --theme-text-white: #fff;
  --theme-box-shadow: rgba(5, 5, 5, 0.3);
  --theme-modal-bg: #160e24;
  --thame-modal-text-gray: #707378;
  --thame-modal-backing-bg: #181a1e;
  --thame-socials-primary: #fff;
  --thame-socials-gray: #707378;
  --thame-btn-square: #313339;
  --thame-close-icon: rgba(255, 255, 255, 0.6);
  --thame-card-btn: #0d0d0d;
  --thame-card-btn-text: #fff;
  --thame-card-border-color: transparent;
  --thame-card-no-image: #707378;
  --thame-card-bg: #071235;
  --thame-card-no-image-bg: #0c1e52;
  --thame-disabled-btn-text: #343639;
  --theme-avatar-bg: #ac28f5;
  --theme-avatar-text: #fff;
  --theme-nfts-fade-light: rgba(5, 5, 5, 0.1);
  --theme-nfts-fade: rgba(5, 5, 5, 0.95);
  --theme-nfts-fade-footer: #050505;
  --theme-switch-bg: #383838;
  --theme-text-title: #ffffff;
}

.light-mode {
  --theme-body-color: rgb(231, 226, 240);
  --theme-footer-color: #fafafa;
  --theme-card-color: #ffffff;
  --thame-card-shadow: #c5c5c5;
  --theme-border-color: #e8e8e8;
  --theme-text-primary: #000;
  --theme-text-secondary: #555;
  --theme-text-gray: #999;
  --thame-text-light-gray: #999;
  --thame-text-dark-gray: #444;
  --theme-text-white: #000;
  --theme-box-shadow: rgba(197, 197, 197, 0.25);
  --theme-modal-bg: #fff;
  --thame-modal-backing-bg: #f3f3f3;
  --thame-socials-primary: #ac28f5;
  --thame-socials-gray: #ac28f5;
  --thame-btn-square: #999;
  --thame-close-icon: rgba(29, 29, 29, 0.6);
  --thame-card-btn: #fbfbfb;
  --thame-card-btn-text: #555;
  --thame-card-border-color: #e7e7e7;
  --thame-card-bg: #fff;
  --thame-card-no-image: #999;
  --thame-card-no-image-bg: #ededed;
  --thame-disabled-btn-text: #c2c3c5;
  --theme-avatar-bg: #000;
  --theme-avatar-text: #ac28f5;
  --theme-nfts-fade-light: rgba(241, 238, 238, 0.1);
  --theme-nfts-fade: rgba(221, 217, 217, 0.95);
  --theme-nfts-fade-footer: #eee7e7;
  --theme-switch-bg: #eaeaea;
  --theme-text-title: #45c98f;
}

@mixin cardHead {
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  grid-gap: 16px;
  margin-bottom: 19px;

  h4 {
    margin-bottom: 0;
    color: var(--theme-text-primary);

    i {
      font-size: 9px;
      position: relative;
      top: -3px;
    }
  }

  span {
    font-size: 16px;
    line-height: 20px;
    color: #999;

    i {
      position: relative;
      top: 2px;
    }
  }

  img {
    max-width: 100%;
  }
}

// Flex
@mixin flex-align($align-position) {
  display: flex;
  align-items: $align-position;
}

@mixin flex-justify($justify-position) {
  display: flex;
  justify-content: $justify-position;
}

@mixin flex-justify-align($justify-position, $align-position) {
  display: flex;
  justify-content: $justify-position;
  align-items: $align-position;
}

/* Breakpoint mixins */
@mixin media-tablet {
  @media (max-width: $tablet-breackpoint) {
    @content;
  }
}

@mixin media-mobile {
  @media (max-width: $mobile-breackpoint) {
    @content;
  }
}

@mixin media-low-mobile {
  @media (max-width: $low-mobile-breackpoint) {
    @content;
  }
}

.inner {
  padding: 30px 0;
  background: var(--theme-body-color);
  display: flex;
  flex-direction: column;
  align-items: center;

  .error {
    color: $pink !important;
    text-align: center;
    text-align: left;
  }

  .title {
    font-family: 'Chillax';
    color: var(--theme-text-title);
    text-align: center;
    margin-bottom: 50px;
    font-size: 24px;
  }

  .actionBtnWrapp {
    width: 100%;
    text-align: flex-start;

    button {
      min-height: 43px;
      min-width: 101px;
      font-size: 16px;

      font-family: 'Chillax';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
    }
  }

  .subTitle {
    margin-top: 20px;
    color: var(--theme-text-primary);
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
  }

  .amount {
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid var(--theme-text-primary);
    margin-top: 10px;
    position: relative;

    &Focus {
      border-color: $primary;
    }

    &.inputError {
      border-color: $pink !important;
    }
  }

  .allInputsWrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .fileName {
    position: absolute;
    top: 3px;
    left: 100px;
  }

  .fileBtn {
    padding: 5px 10px;
    // width: 59.88px;
    // height: 24.44px;
    font-family: 'Chillax';
    font-style: normal;
    font-weight: 600;
    font-size: 8.96px;
    line-height: 120%;

    background: #45c98f;
    border: 0.56px solid #45c98f;
    border-radius: 4.48px;
    cursor: pointer;
    position: absolute;
    top: 4px;
    left: 20px;
    color: #fff;
  }

  .fakeBorder {
    // margin-top: 20px;
    width: 100%;
    max-width: 992px;
    height: auto;
    border: 1px solid var(--theme-text-primary);
    border-radius: 4px;
    inset: -5px 0px 0px;
    position: relative;
    height: 53px;
    &.area {
      min-height: 170px;
      height: auto;
    }
    &.file {
      padding: 10px;
      height: 53px;
    }
    &.titleInput {
      max-width: 582px;
    }
    &__text {
      width: auto;
      margin-left: 20px;
      margin-bottom: 0;
      padding: 0 5px;
      font-family: 'Chillax';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #45c98f;
    }
  }

  .amountInput {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 992px;
    // height: 36px;
    background-color: transparent !important;
    // border: var(--theme-text-primary);
    border: none;
    outline: none;
    color: var(--theme-text-primary);
    padding-left: 14px;
    padding-right: 60px;
    position: relative;
    z-index: 200;

    &:-internal-autofill-selected {
      background-color: transparent !important;
    }

    &.invisible {
      visibility: hidden;
      height: 0;
      display: none;
    }

    &.titleInput {
      max-width: 582px;
    }

    &:focus,
    &:active {
      border: none;
      outline: none;
      background-color: transparent !important;
    }

    &::placeholder {
      color: var(--theme-text-gray);
    }
  }

  .areaInput {
    width: 100%;
    max-width: 992px;
    height: 100px;
    background-color: transparent;
    border: var(--theme-text-primary);
    color: var(--theme-text-primary);
    padding-left: 14px;
    padding-right: 60px;

    border: none;
    outline: none;
    position: relative;
    z-index: 150;
    min-height: 170px;
    height: auto;

    &:focus,
    &:active {
      border: none;
      outline: none;
    }

    &::placeholder {
      color: var(--theme-text-gray);
    }
  }

  .inputs {
    width: 100%;
    max-width: 992px;
    @include flex-justify(space-between);
    @include media-mobile {
      flex-direction: column;
    }
  }

  .input {
    width: 47%;
    max-width: 582px;
    @include media-mobile {
      width: 100%;
    }
  }

  .inputDate {
    width: 30%;
    .dateInput .ant-picker-input > input {
      color: var(--theme-text-primary);
    }
  }

  .dateInput {
    border: 1px solid var(--theme-text-primary);
    width: 100%;
    height: 36px;
    background-color: transparent;
    color: var(--theme-text-primary);
    // padding-left: 14px;
    // padding-right: 60px;
    .ant-picker .ant-picker-input > input {
      color: var(--theme-text-primary) !important;
    }

    &::placeholder {
      color: var(--theme-text-gray);
    }
  }

  .auctionInputWrapper {
    display: flex;
    align-items: center;
  }

  .amountAuction {
    width: 129px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid var(--theme-border-color);
    margin-right: 15px;

    &.amountFocus {
      border-color: $primary;
    }
  }

  .auctionInput {
    width: 100%;
    height: 36px;
    background-color: transparent;
    border: transparent;
    border: none;
    color: var(--theme-text-primary);
  }

  .desc {
    margin-top: 10px;
    color: var(--theme-text-gray);
    font-weight: normal;
    display: flex;
    align-items: center;

    span {
      color: var(--theme-text-primary);
      margin-left: 8px;

      &.innactiveFee {
        text-decoration: line-through;
        color: var(--theme-text-gray);
        font-size: 10px;
      }

      &.activeFee {
        color: $primary;
        margin-right: 5px;
      }
    }
  }

  & :global {
    .ant-tabs-tab.ant-tabs-tab-active {
      border-color: $primary;

      & .ant-tabs-tab-btn {
        color: $primary;
      }
    }

    .ant-tabs-tab {
      color: var(--theme-text-primary);
      font-size: 16px;
      font-weight: 500;
      width: 50%;
      background-color: var(--theme-card-color);
      border-radius: 10px;
      border: 1px solid transparent;

      &:hover {
        color: $primaryHover;
      }
    }

    .ant-tabs-tab-disabled {
      color: var(--thame-disabled-btn-text);
      background-color: var(--thame-modal-backing-bg);

      &:hover {
        color: var(--thame-disabled-btn-text);
      }
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin-left: 16px;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
      width: 100%;
    }

    .ant-tabs-tab-btn {
      margin: 0 auto;
    }

    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-remove:focus,
    .ant-tabs-tab-btn:active,
    .ant-tabs-tab-remove:active {
      color: inherit;
    }

    .ant-modal-content {
      background: var(--theme-body-color);
    }

    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-bottom > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before,
    .ant-tabs-bottom > div > .ant-tabs-nav::before {
      display: none;
    }

    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-tabs {
      overflow: inherit;
    }

    .ant-input-number-handler-wrap {
      display: none;
    }

    .ant-input-number:focus,
    .ant-input-number-focused,
    .ant-input-number:hover {
      border-color: var(--theme-border-color);
      box-shadow: none;
    }

    .ant-modal-wrap {
      z-index: 1500;
    }
  }
}

// Dropdown token list
.tokenDropdown {
  @include flex-align(center);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  height: 100%;

  & :global {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select,
    .ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      height: 100%;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 44px;
      font-weight: 600;
      font-size: 16px;
      color: var(--theme-text-primary);
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: var(--thame-modal-backing-bg);
      border: none;
      border-radius: 0;
    }

    .ant-select-arrow {
      font-size: 14px;
      right: 15px;
      color: var(--thame-text-dark-gray);
      transition: transform 0.2s ease-in-out;
    }

    .ant-select-open .ant-select-arrow {
      transform: rotate(180deg);
      transition: transform 0.2s ease-in-out;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      border-color: transparent;
      box-shadow: none;
    }
  }
}

:global {
  .ant-select-dropdown {
    background-color: var(--thame-modal-backing-bg);
    border-radius: 4px;
    border: 1px solid var(--theme-border-color);
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: $primary;
    background-color: transparent;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: $primaryHover;
    background-color: transparent;
  }

  .ant-select-item {
    font-weight: normal;
    font-size: 14px;
    background-color: transparent;
    color: var(--theme-text-primary);
  }
}

// input:-internal-autofill-selected {
//   background-color: transparent;
// }

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
//   box-shadow: 0 0 0 30px white inset !important;
//   background-color: blue;
// }

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
//   transition: background-color 5000s ease-in-out 0s;
// }

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: -internal-light-dark(
    rgba(232, 240, 254, 0.1),
    rgba(70, 90, 126, 0.1)
  ) !important;
  color: fieldtext !important;
}
