h1 {
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
}

h2 {
  font-weight: 800;
  font-size: 42px;
  line-height: 42px;
}

h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
}

h5 {
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
}
