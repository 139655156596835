// Breakpoints

$xs_bp: 468px;
$sm_bp: 576px;
$sp_bp: 768px;
$tb_bp: 992px;
$dt_bp: 1200px;
$bdt_bp: 1330px;

// Breackpoints Use in mixins
$low-mobile-breackpoint: 375px;
$mobile-breackpoint: 1024px;
$tablet-breackpoint: 1310px;

// Colors
$primary: #45c98f;
// $primary: #ac28f5;
$primaryHover: #4aca93;
// $primaryHover: #c55fff;
$secondary: #ccff74;
$success: #8ad52a;
// $btnPrimary: #98c063;
// $btnSecondary: #b1cf8a;
$pink: #ff809e;
$green: #3feb7e;
$dark: #444444;
$text: #555;
$text-primary: #efefef;
$gray: #999;
$white: #fff;
$black: #0d0d0d;
$light-gray: #707378;

$gradient-yellow: linear-gradient(180deg, #ffd770 0%, #ff9f15 100%);
$gradient-pink: linear-gradient(180deg, #fdbd91 0%, #f66daf 100%);
$gradient-green: linear-gradient(180deg, #beec82 0%, #7bc719 100%);

:root {
  --theme-body-color: #262626;
  --theme-footer-color: #242424;
  --theme-card-color: #181a1e;
  --thame-card-shadow: transparent;
  --theme-border-color: #142149;
  --theme-text-primary: #efefef;
  --theme-text-secondary: #b7b7b7;
  --theme-text-gray: #707378;
  --thame-text-light-gray: #efefef;
  --thame-text-dark-gray: #efefef;
  --theme-text-white: #fff;
  --theme-box-shadow: rgba(5, 5, 5, 0.3);
  --theme-modal-bg: #160e24;
  --thame-modal-text-gray: #707378;
  --thame-modal-backing-bg: #181a1e;
  --thame-socials-primary: #fff;
  --thame-socials-gray: #707378;
  --thame-btn-square: #313339;
  --thame-close-icon: rgba(255, 255, 255, 0.6);
  --thame-card-btn: #0d0d0d;
  --thame-card-btn-text: #fff;
  --thame-card-border-color: transparent;
  --thame-card-no-image: #707378;
  --thame-card-bg: #071235;
  --thame-card-no-image-bg: #0c1e52;
  --thame-disabled-btn-text: #343639;
  --theme-avatar-bg: #ac28f5;
  --theme-avatar-text: #fff;
  --theme-nfts-fade-light: rgba(5, 5, 5, 0.1);
  --theme-nfts-fade: rgba(5, 5, 5, 0.95);
  --theme-nfts-fade-footer: #050505;
  --theme-switch-bg: #383838;
  --theme-text-title: #ffffff;
}

.light-mode {
  --theme-body-color: rgb(231, 226, 240);
  --theme-footer-color: #fafafa;
  --theme-card-color: #ffffff;
  --thame-card-shadow: #c5c5c5;
  --theme-border-color: #e8e8e8;
  --theme-text-primary: #000;
  --theme-text-secondary: #555;
  --theme-text-gray: #999;
  --thame-text-light-gray: #999;
  --thame-text-dark-gray: #444;
  --theme-text-white: #000;
  --theme-box-shadow: rgba(197, 197, 197, 0.25);
  --theme-modal-bg: #fff;
  --thame-modal-backing-bg: #f3f3f3;
  --thame-socials-primary: #ac28f5;
  --thame-socials-gray: #ac28f5;
  --thame-btn-square: #999;
  --thame-close-icon: rgba(29, 29, 29, 0.6);
  --thame-card-btn: #fbfbfb;
  --thame-card-btn-text: #555;
  --thame-card-border-color: #e7e7e7;
  --thame-card-bg: #fff;
  --thame-card-no-image: #999;
  --thame-card-no-image-bg: #ededed;
  --thame-disabled-btn-text: #c2c3c5;
  --theme-avatar-bg: #000;
  --theme-avatar-text: #ac28f5;
  --theme-nfts-fade-light: rgba(241, 238, 238, 0.1);
  --theme-nfts-fade: rgba(221, 217, 217, 0.95);
  --theme-nfts-fade-footer: #eee7e7;
  --theme-switch-bg: #eaeaea;
  --theme-text-title: #45c98f;
}

@mixin cardHead {
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  grid-gap: 16px;
  margin-bottom: 19px;

  h4 {
    margin-bottom: 0;
    color: var(--theme-text-primary);

    i {
      font-size: 9px;
      position: relative;
      top: -3px;
    }
  }

  span {
    font-size: 16px;
    line-height: 20px;
    color: #999;

    i {
      position: relative;
      top: 2px;
    }
  }

  img {
    max-width: 100%;
  }
}

// Flex
@mixin flex-align($align-position) {
  display: flex;
  align-items: $align-position;
}

@mixin flex-justify($justify-position) {
  display: flex;
  justify-content: $justify-position;
}

@mixin flex-justify-align($justify-position, $align-position) {
  display: flex;
  justify-content: $justify-position;
  align-items: $align-position;
}

/* Breakpoint mixins */
@mixin media-tablet {
  @media (max-width: $tablet-breackpoint) {
    @content;
  }
}

@mixin media-mobile {
  @media (max-width: $mobile-breackpoint) {
    @content;
  }
}

@mixin media-low-mobile {
  @media (max-width: $low-mobile-breackpoint) {
    @content;
  }
}

.nftInfo {
  margin-bottom: 65px;

  &__specs {
    @include flex-justify-align(space-between, center);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
    padding: 20px 0;
    @include media-mobile {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &_img {
      max-width: 540px;
      display: flex;
      align-items: center;
      @include media-mobile {
        margin-bottom: 20px;
      }
    }
    &_description {
      color: var(--theme-text-secondary);
      margin-left: 100px;
      @include media-mobile {
        width: 100%;
        margin: 0;
      }
      h3 {
        color: var(--theme-text-white);
        margin-bottom: 20px;
        font-size: 34px;
        white-space: nowrap;
        @include media-mobile {
          white-space: inherit;
          line-height: 1;
        }
      }
      ul {
        line-height: 2;
        list-style: none;
        li {
          span {
            color: var(--theme-text-white);
            font-weight: bold;
          }
        }
      }
    }
  }

  &__auctionContent {
    @include flex-justify(space-between);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
    padding: 20px 0;
    position: relative;
    z-index: 10;
    @include media-mobile {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    h3 {
      color: var(--theme-text-white);
      margin-bottom: 20px;
      font-size: 34px;
      white-space: nowrap;
      @include media-mobile {
        white-space: inherit;
        line-height: 1;
      }
    }
    &::after {
      content: '';
      position: absolute;
      right: calc((100% - 100vw) / 2);
      top: 0;
      width: 100vw;
      height: 100%;
      background-color: var(--thame-card-btn);
      z-index: 1;
    }
    &_img {
      max-width: 540px;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 9;
      transform: translateY(-10px);
      @include media-mobile {
        transform: translateY(0);
      }
    }
    &_description {
      color: var(--theme-text-secondary);
      position: relative;
      z-index: 8;
      h3 {
        color: var(--theme-text-white);
        margin-bottom: 20px;
      }
    }
  }

  &__opening_bid {
    margin-bottom: 10px;
    margin-right: 20px;
  }

  &__terms {
    @include flex-justify(center);
    flex-direction: column;

    h3 {
      color: var(--theme-text-white);
      text-align: center;
      font-size: 18px;
      margin: 10px 0;
    }

    &_title {
      color: var(--theme-text-white);
      text-align: center;
      margin-bottom: 25px;
      font-size: 24px;
    }
  }

  &__card {
    display: flex;
    padding: 50px 0;

    @include media-mobile {
      flex-direction: column;
      align-items: baseline;
    }

    &NotFound {
      font-weight: 600;
      font-size: 25px;
      line-height: 1;
      color: var(--theme-text-primary);
      margin-bottom: 0;

      @include media-mobile {
        font-size: 20px;
      }
    }

    &Video {
      width: 100%;
      height: 100%;

      video {
        display: block;
      }
    }

    &Image {
      @include flex-justify(center);
      object-fit: contain;
      width: 100%;
      max-width: 520px;
      height: 100%;
      max-height: 554px;
      border-radius: 20px;
      margin-right: 50px;
      overflow: hidden;

      @include media-mobile {
        max-width: 400px;
        margin-right: 0;
        margin-bottom: 30px;
      }

      &NotFound {
        @include flex-align(center);
        width: 50%;
        height: 50%;
        padding: 50px;
        margin: 0 auto;

        & > svg {
          width: 100%;
          height: 100%;
        }
      }

      & > img {
        display: block;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  &__info {
    flex-grow: 1;
  }

  &__heading {
    @include flex-align(baseline);
    flex-direction: column;
    margin-bottom: 35px;
  }

  &__subtitle {
    order: 1;
    font-weight: 600;
    font-size: 20px;
    line-height: 1;
    color: var(--theme-text-primary);
    margin-bottom: 20px;
  }

  &__bidsWrapper {
    display: flex;
  }

  &__name {
    order: 1;
    font-weight: 600;
    font-size: 36px;
    line-height: 1;
    color: var(--theme-text-primary);
    margin-bottom: 0;

    @include media-mobile {
      font-size: 32px;
    }
  }

  &__collection {
    @include flex-align(center);
    flex-wrap: wrap;
    color: var(--theme-text-gray);
    font-weight: normal;
    margin-bottom: 12px;

    @include media-mobile {
      font-size: 16px;
    }

    &Title {
      margin-right: 6px;
    }

    &Name {
      font-size: 18px;
      line-height: 1;
      color: var(--theme-text-secondary);

      @include media-mobile {
        font-size: 16px;
      }
    }
  }

  &__checked {
    display: block;
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-left: 5px;

    & > img {
      display: block;
      width: 100%;
      height: 100%;
    }

    @include media-tablet {
      width: 14px;
      min-width: 14px;
      height: 14px;
    }

    @include media-mobile {
      width: 12px;
      min-width: 12px;
      height: 12px;
      order: -1;
      margin-right: 5px;
    }
  }

  &__subInfo {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  &__owned {
    font-weight: normal;
    color: var(--theme-text-gray);
    margin-bottom: 0;
    &__line {
      width: 100%;
      border-bottom: 1px solid #313339;
      margin: 20px 0;
    }

    button {
      margin-left: 5px;
    }

    a {
      color: $primary;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: $primaryHover;
        transition: color 0.3s ease-in-out;
      }
    }
  }

  &__description {
    max-width: 540px;
    width: 100%;
    max-height: 120px;
    margin-bottom: 25px;
    font-weight: normal;
    color: var(--theme-text-secondary);
    overflow-y: scroll;

    @include media-mobile {
      max-width: 100%;
    }

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    &::-webkit-scrollbar-button {
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      height: 50px;
      background-color: var(--theme-border-color);
      border-radius: 50px;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }
  }

  &__author {
    @include flex-align(center);
    width: 100%;
    padding: 28px 0;
    margin-bottom: 25px;
    position: relative;

    @include media-mobile {
      width: 100%;
      padding-right: 15px;
    }

    &:after,
    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--theme-border-color);
    }

    &:after {
      bottom: 0;
    }

    &:before {
      top: 0;
    }

    &Name {
      color: var(--theme-text-gray);
      font-weight: normal;
      margin-bottom: 0;

      > a {
        color: $primary;
        transition: color 0.3s ease-in-out;

        &:hover {
          color: $primaryHover;
          transition: color 0.3s ease-in-out;
        }
      }
    }
  }

  &__socials {
    @include flex-align(center);
    list-style-type: none;
    margin: 0 30px;
    padding: 0;

    &Item {
      width: 22px;
      min-width: 22px;
      height: 22px;
      margin-right: 15px;
      background-color: var(--thame-socials-gray);
      overflow: hidden;
      border-radius: 50%;
      transition: 0.3s all;

      &:hover {
        background-color: $primaryHover;
        transition: 0.3s all;
      }

      &:last-child {
        margin-right: 0;
      }

      & a {
        @include flex-justify-align(center, center);
        width: 100%;
        height: 100%;

        & i {
          position: relative;
          top: 2px;
          font-size: 13px;
          color: var(--theme-body-color);
        }
      }
    }
  }

  &__stock {
    font-weight: normal;
    color: var(--theme-text-primary);
    margin-bottom: 20px;

    span {
      color: $green;
    }
  }

  // ==========================

  &__nftBtn {
    width: 170px;
    margin-top: 25px;
  }

  &__purchase {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  &__btns {
    display: flex;

    > button {
      margin-right: 15px;
    }
  }

  &__priceTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    color: var(--theme-text-gray);
    margin-bottom: 12px;
  }

  &__buyout_priceTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: var(--theme-text-white);
  }

  &__price {
    @include flex-align(flex-end);
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    // line-height: 30px;
    color: var(--theme-text-primary);
    img {
      margin-right: 5px;
    }
    span {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 18px;
      color: var(--theme-text-gray);
      margin-left: 8px;
    }
  }

  &__endDate {
    margin-top: 20px;
  }

  &__buyoutPrice {
    @include flex-align(flex-end);
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    // line-height: 30px;
    color: var(--theme-text-white);
    margin-left: 20px;

    img {
      width: 9px;
      height: 16px;
      margin-right: 5px;
      margin-left: 5px;
    }

    span {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: var(--theme-text-white);
      margin: 0;
    }
  }

  // ==========================

  &__nftDescription {
    height: auto;
    overflow-y: hidden;
    color: var(--theme-text-white);
    position: relative;
    max-height: 200px;
    h2 {
      font-size: 18px;
      color: var(--theme-text-white);
    }
    ul {
      li {
        // max-width: 730px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--theme-text-secondary);
      }
    }
    a {
      color: $primary;
      text-decoration: underline;
    }
    &_fade {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 80px;
      background: linear-gradient(
        180deg,
        var(--theme-nfts-fade-light) 0%,
        var(--theme-nfts-fade) 74.43%,
        var(--theme-nfts-fade-footer) 100%
      );
      z-index: 1;
    }
    &_list {
      margin-top: 10px;
    }
    &_active {
      max-height: 100%;
    }
    h3 {
      color: var(--theme-text-white);
      margin-top: 5px;
      margin-bottom: 20px;
      font-size: 34px;
      white-space: nowrap;
      @include media-mobile {
        white-space: inherit;
        line-height: 1;
      }
    }
    p {
      // max-width: 730px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--theme-text-secondary);
    }
    &_btn {
      position: absolute;
      bottom: -5px;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.003em;
      color: var(--theme-text-white);
      z-index: 2;
      cursor: pointer;
    }
  }

  &__tab_desc {
    position: absolute;
    right: 20%;
    top: 20px;
    transform: translate(-20%, 0);
  }

  &__tab_text {
    max-width: 50%;
    overflow: scroll;
    // max-height: calc((100vw) / 2);
    position: absolute;
    right: -15%;
    top: 100px;
    transform: translate(-25%, 0);
  }

  &__tabsGroup {
    margin-bottom: 120px;
    margin-top: 50px;
    @include media-mobile {
      margin-bottom: 70px;
    }
  }

  &__details {
    width: 100%;
    max-width: 445px;
    list-style-type: none;

    & > li {
      @include flex-justify-align(space-between, center);
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }

      h4 {
        font-weight: normal;
        color: var(--theme-text-gray);
        margin-right: 15px;
      }

      p {
        font-weight: 500;
        color: var(--theme-text-primary);
      }

      a {
        font-weight: 500;
        color: $primary;
      }

      h4,
      p,
      a {
        margin-bottom: 0;
        font-size: 18px;
      }
    }
  }

  &__properties {
    @include flex-justify(flex-start);
    flex-wrap: wrap;
    padding-top: 30px;
    list-style-type: none;
    margin-bottom: 0;

    & > li {
      @include flex-align(center);
      flex-direction: column;
      width: 255px;
      min-width: 255px;
      height: 119px;
      background: var(--theme-card-color);
      border-radius: 20px;
      padding-top: 20px;
      border: 1px solid var(--theme-border-color);
      margin-right: 30px;
      margin-bottom: 30px;

      @include media-mobile {
        width: 218px;
      }

      @media (max-width: $sm_bp) {
        width: 165px;
      }
    }

    &Type {
      font-weight: 500;
      font-size: 16px;
      line-height: 1;
      margin-bottom: 15px;
      color: $primary;
    }

    &Value {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 1;
      color: var(--theme-text-primary);
      text-align: center;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 3px 10px;
      width: 100%;

      @include media-mobile {
        font-size: 20px;
      }
    }
  }

  // ==========================

  &__activeFilters {
    margin-top: 10px;
  }

  &__clearFilter {
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: $primary;
    margin-left: 26px;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  &__btnGroup {
    @include flex-justify-align(center, center);
    flex-wrap: wrap;
  }

  &__error {
    margin-top: 10px;
    color: $pink;
  }
}

.nftInfo__btnAction {
  @include flex-align(center);
  margin: 0 0 15px;

  button {
    height: 40px;
    min-height: 40px;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}
