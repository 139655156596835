// Breakpoints

$xs_bp: 468px;
$sm_bp: 576px;
$sp_bp: 768px;
$tb_bp: 992px;
$dt_bp: 1200px;
$bdt_bp: 1330px;

// Breackpoints Use in mixins
$low-mobile-breackpoint: 375px;
$mobile-breackpoint: 1024px;
$tablet-breackpoint: 1310px;

// Colors
$primary: #45c98f;
// $primary: #ac28f5;
$primaryHover: #4aca93;
// $primaryHover: #c55fff;
$secondary: #ccff74;
$success: #8ad52a;
// $btnPrimary: #98c063;
// $btnSecondary: #b1cf8a;
$pink: #ff809e;
$green: #3feb7e;
$dark: #444444;
$text: #555;
$text-primary: #efefef;
$gray: #999;
$white: #fff;
$black: #0d0d0d;
$light-gray: #707378;

$gradient-yellow: linear-gradient(180deg, #ffd770 0%, #ff9f15 100%);
$gradient-pink: linear-gradient(180deg, #fdbd91 0%, #f66daf 100%);
$gradient-green: linear-gradient(180deg, #beec82 0%, #7bc719 100%);

:root {
  --theme-body-color: #262626;
  --theme-footer-color: #242424;
  --theme-card-color: #181a1e;
  --thame-card-shadow: transparent;
  --theme-border-color: #142149;
  --theme-text-primary: #efefef;
  --theme-text-secondary: #b7b7b7;
  --theme-text-gray: #707378;
  --thame-text-light-gray: #efefef;
  --thame-text-dark-gray: #efefef;
  --theme-text-white: #fff;
  --theme-box-shadow: rgba(5, 5, 5, 0.3);
  --theme-modal-bg: #160e24;
  --thame-modal-text-gray: #707378;
  --thame-modal-backing-bg: #181a1e;
  --thame-socials-primary: #fff;
  --thame-socials-gray: #707378;
  --thame-btn-square: #313339;
  --thame-close-icon: rgba(255, 255, 255, 0.6);
  --thame-card-btn: #0d0d0d;
  --thame-card-btn-text: #fff;
  --thame-card-border-color: transparent;
  --thame-card-no-image: #707378;
  --thame-card-bg: #071235;
  --thame-card-no-image-bg: #0c1e52;
  --thame-disabled-btn-text: #343639;
  --theme-avatar-bg: #ac28f5;
  --theme-avatar-text: #fff;
  --theme-nfts-fade-light: rgba(5, 5, 5, 0.1);
  --theme-nfts-fade: rgba(5, 5, 5, 0.95);
  --theme-nfts-fade-footer: #050505;
  --theme-switch-bg: #383838;
  --theme-text-title: #ffffff;
}

.light-mode {
  --theme-body-color: rgb(231, 226, 240);
  --theme-footer-color: #fafafa;
  --theme-card-color: #ffffff;
  --thame-card-shadow: #c5c5c5;
  --theme-border-color: #e8e8e8;
  --theme-text-primary: #000;
  --theme-text-secondary: #555;
  --theme-text-gray: #999;
  --thame-text-light-gray: #999;
  --thame-text-dark-gray: #444;
  --theme-text-white: #000;
  --theme-box-shadow: rgba(197, 197, 197, 0.25);
  --theme-modal-bg: #fff;
  --thame-modal-backing-bg: #f3f3f3;
  --thame-socials-primary: #ac28f5;
  --thame-socials-gray: #ac28f5;
  --thame-btn-square: #999;
  --thame-close-icon: rgba(29, 29, 29, 0.6);
  --thame-card-btn: #fbfbfb;
  --thame-card-btn-text: #555;
  --thame-card-border-color: #e7e7e7;
  --thame-card-bg: #fff;
  --thame-card-no-image: #999;
  --thame-card-no-image-bg: #ededed;
  --thame-disabled-btn-text: #c2c3c5;
  --theme-avatar-bg: #000;
  --theme-avatar-text: #ac28f5;
  --theme-nfts-fade-light: rgba(241, 238, 238, 0.1);
  --theme-nfts-fade: rgba(221, 217, 217, 0.95);
  --theme-nfts-fade-footer: #eee7e7;
  --theme-switch-bg: #eaeaea;
  --theme-text-title: #45c98f;
}

@mixin cardHead {
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  grid-gap: 16px;
  margin-bottom: 19px;

  h4 {
    margin-bottom: 0;
    color: var(--theme-text-primary);

    i {
      font-size: 9px;
      position: relative;
      top: -3px;
    }
  }

  span {
    font-size: 16px;
    line-height: 20px;
    color: #999;

    i {
      position: relative;
      top: 2px;
    }
  }

  img {
    max-width: 100%;
  }
}

// Flex
@mixin flex-align($align-position) {
  display: flex;
  align-items: $align-position;
}

@mixin flex-justify($justify-position) {
  display: flex;
  justify-content: $justify-position;
}

@mixin flex-justify-align($justify-position, $align-position) {
  display: flex;
  justify-content: $justify-position;
  align-items: $align-position;
}

/* Breakpoint mixins */
@mixin media-tablet {
  @media (max-width: $tablet-breackpoint) {
    @content;
  }
}

@mixin media-mobile {
  @media (max-width: $mobile-breackpoint) {
    @content;
  }
}

@mixin media-low-mobile {
  @media (max-width: $low-mobile-breackpoint) {
    @content;
  }
}

.header {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1200;
  // background: var(--theme-body-color);
  // border-bottom: 1px solid var(--theme-border-color);

  &__fixed {
    position: fixed;
  }

  &__row {
    @include flex-align(center);
    min-height: 100px;
    padding: 15px 0;
    position: relative;

    > div {
      text-align: left;
    }
  }

  &__search {
    position: relative;
    background: var(--theme-body-color);
    border: 1px solid var(--theme-border-color);
    border-radius: 10px;
    margin-right: 24px;
    max-width: 300px;
    width: 100%;
    height: 43px;

    > button {
      position: absolute;
      left: 18px;
      background: transparent;
      border: 0;
      top: 11px;
      cursor: pointer;
    }

    input {
      border: 0;
      background: transparent;
      padding: 11px 15px 11px 48px;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.003em;
      width: 100%;

      &::placeholder {
        font-weight: normal;
        font-size: 14px;
        line-height: 1;
        color: var(--thame-btn-square);
      }

      &:focus {
        outline: none;
      }
    }

    i:before {
      color: var(--theme-text-gray);
    }
  }

  &__logo {
    display: inline-flex;
    width: 76px;
    margin-right: 40px;
  }

  @include media-tablet {
    &__search {
      max-width: 269px;
    }
  }

  @include media-mobile {
    &__row {
      width: 100%;
      // for search
      //min-height: 138px;
      min-height: 75px;
      justify-content: space-between;
      // for search
      //padding-bottom: 75px;
      padding-bottom: 5px;
      padding-top: 5px;
      position: relative;
    }

    &__search {
      max-width: 100%;
      margin-right: 0;
      position: absolute;
      bottom: 16px;
      left: 0;
      height: 36px;

      input {
        padding-top: 7px;
        padding-bottom: 7px;
      }

      & > button {
        top: 8px;
      }
    }

    &__logo {
      position: absolute;
      top: 20px;
      left: calc((100% - 60px) / 2);
      width: 60px;
      margin-right: 0;

      svg {
        width: 60px;
        height: 30px;
      }
    }
  }

  @include media-low-mobile {
    &__logo {
      left: 60px;
    }
  }
}

.link {
  display: block;
  min-width: 198px;
  color: $primary;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  margin-top: 10px;
  &:hover {
    // font-weight: bold;
    color: $primary;
  }
}

.nav {
  &_items {
    position: absolute;
    opacity: 0;
    padding: 0;
    background: transparent;
    border: 0;
    display: flex;
    pointer-events: none;
    transition: 0.3s all;
    box-shadow: none;
    font-size: inherit;
    // border: none;

    @media (max-width: $bdt_bp) {
      display: grid;
      top: 100%;
      width: 100%;
      z-index: 223;
      padding: 20px;
      left: auto;
      //right: 50px;
      max-width: $xs_bp;
      color: $white;
      height: auto;

      &:hover {
        background: $primary;
        color: #fff;
      }

      @media (max-width: $tb_bp) {
        max-width: $tb_bp;
        right: auto;
        left: 0;
      }

      @include media-mobile {
        display: grid;
        top: 100%;
        width: 100%;
        max-width: 200px;
        z-index: 223;
        background-color: var(--theme-body-color);
        padding: 20px 0;
        box-shadow: none;
        // border: 1px solid var(--theme-border-color);
        border-left: none;
        left: -16px;
        color: $white;
        height: 100vh;
        border-radius: 0;

        &:hover,
        &:focus,
        &:active {
          background-color: var(--theme-body-color);
          // border: 1px solid var(--theme-border-color);
          border-left: none;
          color: $white;
        }

        > a,
        > button {
          justify-content: center;
          margin-top: 20px;

          &:hover {
            color: #fff;
          }
        }

        button {
          text-align: center;
        }
      }

      ul {
        li {
          padding: 14px 15px;
          text-align: left;
          // border-bottom: 1px solid var(--theme-border-color);

          &:last-child {
            border: 0;
          }
        }
      }

      &:focus {
        box-shadow: none;
      }

      &_active {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &_icon {
    width: 22px;
    background: transparent;
    height: 18px;
    margin-right: 20px;
    padding: 0;
    position: relative;
    z-index: 33;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    border: 0;
    box-shadow: none;
    top: 4px;

    &:hover,
    &:focus {
      background: transparent;

      span {
        position: absolute;
      }
    }

    &:active,
    &:focus,
    &:hover {
      border: 0;
    }

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: var(--theme-text-white);
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 6px;
      }

      &:nth-child(4) {
        top: 12px;
      }
    }

    &_active {
      span {
        &:nth-child(1) {
          top: 6px;
          width: 0;
          left: 50%;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }

        &:nth-child(4) {
          top: 6px;
          width: 0;
          left: 50%;
        }
      }
    }
  }
}

.themeSelector {
  background: transparent;
  padding: 0;
  border: 0;
  width: 24px;
  height: 26px;
  cursor: pointer;
  margin-right: 24px;
}

.activeNav {
  content: '';
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(31, 31, 31, 0.8);
  transition: 0.3s all;
  z-index: 22;
}

.stakedModal {
  :global(.ant-modal-body) {
    padding: 0;
  }

  &__container {
    padding: 30px;
  }

  &__title {
    color: var(--theme-text-white);
    text-align: center;
    margin-bottom: 25px;
    font-size: 24px;
  }

  &__btnWrapp {
    width: 100%;
    margin-top: 30px;
    text-align: center;
  }

  &__btn {
    min-height: 55px;
    min-width: 198px;
  }

  & :global {
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $primary;
    }

    .ant-tabs-tab {
      color: var(--thame-modal-text-gray);
      font-size: 16px;
      font-weight: 500;
    }

    .ant-tabs-tab:hover {
      color: var(--thame-modal-text-gray);
    }

    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-remove:focus,
    .ant-tabs-tab-btn:active,
    .ant-tabs-tab-remove:active {
      color: inherit;
    }

    .ant-modal-content {
      background: var(--theme-body-color);
    }

    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-bottom > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before,
    .ant-tabs-bottom > div > .ant-tabs-nav::before {
      border-color: #c4c4c4;
    }

    .ant-tabs-ink-bar {
      background: $primary;
    }

    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-bottom > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before,
    .ant-tabs-bottom > div > .ant-tabs-nav::before {
      right: -90px;
      width: 600px;
      left: auto;
    }

    .ant-tabs-top > .ant-tabs-nav::after,
    .ant-tabs-bottom > .ant-tabs-nav::after,
    .ant-tabs-top > div > .ant-tabs-nav::after,
    .ant-tabs-bottom > div > .ant-tabs-nav::after {
      content: '';
      position: absolute;
      right: -90px;
      left: auto;
      top: 0;
      width: 600px;
      border-top: 1px solid #c4c4c4;
    }

    .ant-tabs {
      overflow: inherit;
    }

    .ant-input {
      background-color: transparent;
      width: 100%;
      height: 36px;
      background-color: transparent;
      border: var(--theme-text-primary);
      color: var(--theme-text-primary);
      padding-left: 14px;
      padding-right: 60px;

      &::placeholder {
        color: var(--theme-text-gray);
      }
    }

    .ant-input-number-handler-wrap {
      display: none;
    }

    .ant-input-number:focus,
    .ant-input-number-focused,
    .ant-input-number:hover {
      border-color: var(--theme-border-color);
      box-shadow: none;
    }

    .ant-modal-wrap {
      z-index: 1500;
    }
  }
}

.subTitle {
  margin-top: 20px;
  color: var(--theme-text-primary);
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
}

.amount {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid var(--theme-text-primary);
  margin-top: 10px;

  &Focus {
    border-color: $primary;
  }

  &.inputError {
    border-color: $pink !important;
  }
}

.amountInput {
  width: 100%;
  height: 36px;
  background-color: transparent;
  // border: var(--theme-text-primary);
  color: var(--theme-text-primary);
  padding-left: 14px;
  padding-right: 60px;

  &::placeholder {
    color: var(--theme-text-gray);
  }
}

.actionBtnWrapp {
  width: 100%;
  margin-top: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.error {
  color: $pink !important;
  text-align: center;
}

.loginBtn,
.loginBtn:hover,
.loginBtn:focus,
.loginBtn:active {
  width: 92px;
  height: 43px;
  border: 1px solid #45c98f;
  border-radius: 8px;
  background: transparent;

  font-family: 'Chillax';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #45c98f;
}

input[type='checkbox'] {
  width: 0;
  height: 0;
  visibility: hidden;
  &:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }
}

.switchLabel {
  cursor: pointer;
  display: block;
  position: relative;
  width: 54px;
  height: 27px;
  background: var(--theme-switch-bg);
  border-radius: 16px;
  margin-right: 30px;
  &:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 5px;
    width: 19px;
    height: 19px;
    background: #45c98f;
    transition: 0.3s;
    border-radius: 50%;
  }
}

.switchImgLight {
  position: absolute;
  left: 7px;
  top: 4px;
}
.switchImgDark {
  position: absolute;
  right: 10px;
  top: 8px;
}
