@font-face {
  font-family: 'icons';
  src: url('./icons.ttf?26c283705bda0c5a99b1402e45e859c6') format('truetype'),
    url('./icons.woff?26c283705bda0c5a99b1402e45e859c6') format('woff'),
    url('./icons.woff2?26c283705bda0c5a99b1402e45e859c6') format('woff2');
}

i[class^='icon-']:before,
i[class*=' icon-']:before {
  font-family: icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow_right:before {
  content: '\f101';
}
.icon-close:before {
  content: '\f102';
}
.icon-discord:before {
  content: '\f103';
}
.icon-exclamation:before {
  content: '\f104';
}
.icon-exit:before {
  content: '\f105';
}
.icon-facebook:before {
  content: '\f106';
}
.icon-filter:before {
  content: '\f107';
}
.icon-info:before {
  content: '\f108';
}
.icon-instagram:before {
  content: '\f109';
}
.icon-linkedin:before {
  content: '\f10a';
}
.icon-medium:before {
  content: '\f10b';
}
.icon-search:before {
  content: '\f10c';
}
.icon-twitter:before {
  content: '\f10d';
}
