.btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: $white;
  padding: 14px 23px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  background: transparent;
  transition: all 0.3s ease;
  position: relative;
  border-radius: 10px;
  z-index: 10;
  white-space: nowrap;
  min-height: 48px;

  &.mr {
    margin-right: 5px;
  }

  &.explore {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    font-size: 22px;
    line-height: 1;
    span {
      padding: 10px 20px;
      font-size: 14px;
    }
  }

  @media (max-width: 324px) {
    font-size: 12px;
    padding: 12px 16px;

    img {
      display: none;
    }
  }

  &:hover {
    transition: all 0.3s ease;
  }

  img {
    max-width: 18px;
    margin-left: 8px;
  }

  &.secondary {
    color: $primary;
    background: transparent;
    border: 1px solid $primary;
  }

  &.primary {
    color: $white;
    background: $primary;

    &:hover {
      background: $primaryHover;
    }
  }

  &.primary-transparent {
    background-color: var(--theme-card-color);
    border: 1px solid $primary;
    color: $primary;

    &:hover {
      background-color: $primaryHover;
      border-color: $primaryHover;
      color: $white;
    }
  }

  &.transparent {
    border: 1px solid var(--theme-border-color);
    color: var(--theme-text-primary);
  }

  i {
    position: relative;
    margin-left: 18px;
    z-index: 2;
    transition: all 0.15s ease;
  }

  > span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    transition: all 0.15s ease;
  }

  &:disabled {
    background: var(--thame-modal-backing-bg);
    color: var(--thame-disabled-btn-text);
    box-shadow: none;
    cursor: not-allowed;

    &:hover {
      background: var(--thame-modal-backing-bg);
      color: var(--thame-disabled-btn-text);
      box-shadow: none;
    }

    &:before {
      display: none;
    }
  }
}

.btn-square {
  width: 40px;
  height: 40px;
  background-color: var(--theme-body-color);
  border: 1px solid var(--thame-btn-square);
  border-radius: 10px;
  cursor: pointer;
  background-size: 18px;
  transition: all 0.15s ease;

  &__default,
  &__hover {
    display: block;
    width: 18px;
    height: 18px;
    margin: auto;
  }

  &__hover {
    opacity: 0;
    display: none;
  }

  &:hover {
    border: 1px solid $primary;
    transition: all 0.15s ease;
  }

  &:hover &__default {
    opacity: 0;
    display: none;
    transition: all 0.15s ease;
  }

  &:hover &__hover {
    opacity: 1;
    display: block;
    transition: all 0.15s ease;
  }

  &.nft {
    width: 170px;
    background-color: var(--theme-body-color);
    border: 1px solid $primary;
    color: $primary;
    &.close {
      width: auto;
      height: 36px;
      padding: 0 15px 0 25px;
      text-align: center;
      margin-left: 12px;
      background: var(--theme-card-color);
    }
  }
}

.btn-default-small {
  @include flex-align(center);
  min-height: 42px;
  min-width: 42px;
  font-size: 16px;
  line-height: 1;
  font-weight: normal;
  background-color: var(--theme-body-color);
  border: 1px solid var(--theme-border-color);
  border-radius: 10px;
  padding: 7px 23px;
  cursor: pointer;
  transition: all 0.3s ease;

  &.right-icon {
    & i {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  i {
    position: relative;
    margin-right: 10px;
    z-index: 2;
    padding-bottom: 3px;
    transition: all 0.15s ease;
  }

  &:hover,
  &.active {
    color: $primary;
    border-color: $primary;
    transition: all 0.3s ease;
  }

  &.dark {
    background-color: var(--theme-card-color);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var(--thame-modal-backing-bg);
    border-color: var(--thame-modal-backing-bg);
    color: var(--thame-disabled-btn-text);
  }
}

.btn-primary {
  margin-left: 10px;
}

.btn-error {
  background: $primary;
  color: #fff;
  outline: none;
  border: none;
  &:hover,
  &:active,
  &:focus {
    color: #000;
  }
}
