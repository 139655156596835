// Breakpoints

$xs_bp: 468px;
$sm_bp: 576px;
$sp_bp: 768px;
$tb_bp: 992px;
$dt_bp: 1200px;
$bdt_bp: 1330px;

// Breackpoints Use in mixins
$low-mobile-breackpoint: 375px;
$mobile-breackpoint: 1024px;
$tablet-breackpoint: 1310px;

// Colors
$primary: #45c98f;
// $primary: #ac28f5;
$primaryHover: #4aca93;
// $primaryHover: #c55fff;
$secondary: #ccff74;
$success: #8ad52a;
// $btnPrimary: #98c063;
// $btnSecondary: #b1cf8a;
$pink: #ff809e;
$green: #3feb7e;
$dark: #444444;
$text: #555;
$text-primary: #efefef;
$gray: #999;
$white: #fff;
$black: #0d0d0d;
$light-gray: #707378;

$gradient-yellow: linear-gradient(180deg, #ffd770 0%, #ff9f15 100%);
$gradient-pink: linear-gradient(180deg, #fdbd91 0%, #f66daf 100%);
$gradient-green: linear-gradient(180deg, #beec82 0%, #7bc719 100%);

:root {
  --theme-body-color: #262626;
  --theme-footer-color: #242424;
  --theme-card-color: #181a1e;
  --thame-card-shadow: transparent;
  --theme-border-color: #142149;
  --theme-text-primary: #efefef;
  --theme-text-secondary: #b7b7b7;
  --theme-text-gray: #707378;
  --thame-text-light-gray: #efefef;
  --thame-text-dark-gray: #efefef;
  --theme-text-white: #fff;
  --theme-box-shadow: rgba(5, 5, 5, 0.3);
  --theme-modal-bg: #160e24;
  --thame-modal-text-gray: #707378;
  --thame-modal-backing-bg: #181a1e;
  --thame-socials-primary: #fff;
  --thame-socials-gray: #707378;
  --thame-btn-square: #313339;
  --thame-close-icon: rgba(255, 255, 255, 0.6);
  --thame-card-btn: #0d0d0d;
  --thame-card-btn-text: #fff;
  --thame-card-border-color: transparent;
  --thame-card-no-image: #707378;
  --thame-card-bg: #071235;
  --thame-card-no-image-bg: #0c1e52;
  --thame-disabled-btn-text: #343639;
  --theme-avatar-bg: #ac28f5;
  --theme-avatar-text: #fff;
  --theme-nfts-fade-light: rgba(5, 5, 5, 0.1);
  --theme-nfts-fade: rgba(5, 5, 5, 0.95);
  --theme-nfts-fade-footer: #050505;
  --theme-switch-bg: #383838;
  --theme-text-title: #ffffff;
}

.light-mode {
  --theme-body-color: rgb(231, 226, 240);
  --theme-footer-color: #fafafa;
  --theme-card-color: #ffffff;
  --thame-card-shadow: #c5c5c5;
  --theme-border-color: #e8e8e8;
  --theme-text-primary: #000;
  --theme-text-secondary: #555;
  --theme-text-gray: #999;
  --thame-text-light-gray: #999;
  --thame-text-dark-gray: #444;
  --theme-text-white: #000;
  --theme-box-shadow: rgba(197, 197, 197, 0.25);
  --theme-modal-bg: #fff;
  --thame-modal-backing-bg: #f3f3f3;
  --thame-socials-primary: #ac28f5;
  --thame-socials-gray: #ac28f5;
  --thame-btn-square: #999;
  --thame-close-icon: rgba(29, 29, 29, 0.6);
  --thame-card-btn: #fbfbfb;
  --thame-card-btn-text: #555;
  --thame-card-border-color: #e7e7e7;
  --thame-card-bg: #fff;
  --thame-card-no-image: #999;
  --thame-card-no-image-bg: #ededed;
  --thame-disabled-btn-text: #c2c3c5;
  --theme-avatar-bg: #000;
  --theme-avatar-text: #ac28f5;
  --theme-nfts-fade-light: rgba(241, 238, 238, 0.1);
  --theme-nfts-fade: rgba(221, 217, 217, 0.95);
  --theme-nfts-fade-footer: #eee7e7;
  --theme-switch-bg: #eaeaea;
  --theme-text-title: #45c98f;
}

@mixin cardHead {
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  grid-gap: 16px;
  margin-bottom: 19px;

  h4 {
    margin-bottom: 0;
    color: var(--theme-text-primary);

    i {
      font-size: 9px;
      position: relative;
      top: -3px;
    }
  }

  span {
    font-size: 16px;
    line-height: 20px;
    color: #999;

    i {
      position: relative;
      top: 2px;
    }
  }

  img {
    max-width: 100%;
  }
}

// Flex
@mixin flex-align($align-position) {
  display: flex;
  align-items: $align-position;
}

@mixin flex-justify($justify-position) {
  display: flex;
  justify-content: $justify-position;
}

@mixin flex-justify-align($justify-position, $align-position) {
  display: flex;
  justify-content: $justify-position;
  align-items: $align-position;
}

/* Breakpoint mixins */
@mixin media-tablet {
  @media (max-width: $tablet-breackpoint) {
    @content;
  }
}

@mixin media-mobile {
  @media (max-width: $mobile-breackpoint) {
    @content;
  }
}

@mixin media-low-mobile {
  @media (max-width: $low-mobile-breackpoint) {
    @content;
  }
}

.main {
  display: flex;
  height: 196px;
  position: relative;
  margin-bottom: 20px;

  &_name {
    position: absolute;
    bottom: -30px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    font-family: 'Chillax';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    text-align: center;
    color: $primary;
  }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 32px;
    &_title {
      display: flex;
      gap: 10px;
    }
    &_description {
      max-width: 584px;
    }
    &_email {
      span {
        font-family: 'Chillax';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        color: #45c98f;
      }
    }
  }
}
// .circular_landscape {
//   display: inline-block;
//   position: relative;
//   width: 170px;
//   height: 170px;
//   overflow: hidden;
//   border-radius: 50%;
// }
// .circular_landscape img {
//   width: auto;
//   height: 100%;
//   // margin-left: -50px;
// }

.nftCards {
  position: relative;
  z-index: 1000;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 15px auto 30px;
  gap: 30px;

  @include media-mobile {
    max-width: 800px;
  }

  @include media-low-mobile {
    flex-direction: column;
    align-items: center;
  }

  &__line {
    width: 100%;
    height: 1px;
    background: #313339;
    margin: 10px 0;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    // min-width: 255px;
    height: 196px;
    // height: 414px;
    // min-height: 414px;
    // margin: 0 10px 20px;
    // border-radius: 20px;
    // border: 1px solid var(--theme-border-color);
    overflow: hidden;
    background-color: transparent;
    position: relative;
    // filter: drop-shadow(0px 4px 16px var(--thame-card-shadow));
    // transition: all 0.3s ease;
    z-index: 1;

    &::marker {
      background: transparent;
      color: transparent;
    }

    // &:hover {
    // border: 1px solid $primary;
    // filter: drop-shadow(0px 2px 14px rgba(172, 40, 245, 0.5));
    // transition: all 0.15s ease;
    // }

    @include media-tablet {
      // width: 218px;
      // min-width: 218px;
      height: auto;
      // height: 344px;
      // min-height: 324px;
    }

    @include media-mobile {
      // width: 165px;
      // min-width: 165px;
      height: auto;
      // height: 276px;
      min-height: 266px;
      margin: 0 8px 16px;
    }

    @include media-low-mobile {
      margin-right: 0;
    }
  }

  &__imageWrapper {
    // width: 170px;
    // height: 170px;
    // background-color: #fff;
    // border-radius: 100%;

    display: inline-block;
    // position: relative;
    width: 170px;
    height: 170px;
    overflow: hidden;
    border-radius: 50%;
    img {
      // display: block;
      // border-radius: 100%;
      // width: 100%;
      // height: 100%;
      // border-radius: 50%;

      // border-top-left-radius: 50% 50%;
      // border-top-right-radius: 50% 50%;
      // border-bottom-right-radius: 50% 50%;
      // border-bottom-left-radius: 50% 50%;

      width: auto;
      height: 100%;
    }
  }

  &__image {
    width: 276px;
    height: 196px;
    background: var(--theme-footer-color);
    border-radius: 8px;
    // border-bottom: 1px solid var(--theme-border-color);
    @include flex-justify-align(center, center);
    cursor: pointer;
    position: relative;

    // img {
    //   display: block;
    //   border-radius: 100%;
    //   width: 170px;
    //   height: 170px;
    // }

    //

    // @include media-tablet {
    //   height: 238px;
    // }

    // @include media-mobile {
    //   height: 179px;
    // }
  }

  &__noImage {
    @include flex-justify-align(center, center);
    background-color: var(--thame-card-no-image-bg);

    svg path {
      fill: var(--thame-card-no-image);
      stroke: var(--thame-card-no-image);
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding: 15px 12px;
    height: auto;
    // height: 122px;
    background: var(--thame-card-bg);

    @include media-tablet {
      padding: 10px;
      height: auto;
      // height: 86px;
    }

    @include media-mobile {
      padding: 8px;
    }
  }

  &__mainInfo {
    margin-bottom: auto;
  }

  &__name {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.1;
    color: var(--theme-text-primary);
    margin-bottom: 5px;

    & a:hover,
    & a:active,
    & a:focus {
      cursor: pointer;
      color: $gray;
      transition: color 0.3s ease-in-out;
    }

    @include media-tablet {
      font-size: 14px;
    }
  }

  &__collection {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &__collectionInner {
    font-size: 12px;
    line-height: 1;
    color: var(--theme-text-gray);
    margin-bottom: 0;
    margin-right: 5px;

    @include media-tablet {
      font-size: 11px;
      margin-right: 4px;
    }
  }

  &__checked {
    width: 16px;
    min-width: 16px;
    height: 16px;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }

    @include media-tablet {
      width: 14px;
      min-width: 14px;
      height: 14px;
    }

    @include media-mobile {
      width: 12px;
      min-width: 12px;
      height: 12px;
      order: -1;
      margin-right: 5px;
    }
  }

  &__tooltip {
    & span {
      display: none;
    }

    & > div > div:last-child {
      min-width: fit-content;
      max-width: 230px;
      border-radius: 10px;
      background-color: var(--theme-body-color);
      font-size: 12px;
      line-height: 1.1;
      color: var(--theme-text-primary);
      padding: 10px;
      border: 1px solid $primary;
    }
  }

  .about {
    list-style-type: none;
    margin-bottom: 15px;
    padding: 10px 0;
    border-top: 1px solid $primary;
    border-bottom: 1px solid $primary;

    & > li:not(:last-child) {
      margin-bottom: 10px;
    }

    &__row {
      font-size: 12px;
      line-height: 1;
      font-weight: normal;
      color: var(--thame-text-dark-gray);
      margin-bottom: 0;

      & a {
        color: $primary;
        font-weight: 400;
        transition: color 0.3s ease-in-out;

        &:hover {
          color: $primaryHover;
          transition: color 0.3s ease-in-out;
        }
      }

      @include media-tablet {
        font-size: 11px;
      }
    }
  }

  &__price {
    &Wrapper {
      @include flex-align(center);
      flex-direction: row;
      margin-top: 6px;

      span {
        @include flex-align(center);
        font-weight: normal;
        font-size: 12px;
        line-height: 1;
        margin-left: 5px;
        color: var(--theme-text-gray);

        @include media-tablet {
          font-size: 11px;
        }

        & img {
          margin: 0 4px;
        }
      }
    }

    p {
      font-weight: normal;
      font-size: 12px;
      line-height: 1;
      color: var(--theme-text-primary);
      margin-bottom: 0;

      @include media-tablet {
        font-size: 11px;
      }
    }
  }

  &__priceIcon {
    margin-right: 5px;

    img {
      display: block;
    }

    @include media-tablet {
      margin-right: 3px;
    }

    @include media-mobile {
      margin-right: 2px;
    }
  }

  &__claimBtn {
    width: 103px;
    height: 34px;
    min-height: 34px !important;
    position: relative; // important for overtopping default style .btn
    z-index: 100;
    margin-top: 15px;
  }

  &__claimed {
    @include flex-align(center);
    height: 34px;
    margin-top: 11px;

    & > div {
      width: 15px;
      height: 11px;
      margin-right: 4px;

      @include media-mobile {
        width: 12px;
        height: 9px;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 1;
      color: $green;
      letter-spacing: 0.003em;
      margin-bottom: 0;

      @include media-mobile {
        font-size: 12px;
      }
    }
  }
}

// Type of cards
.cardBanned {
  img {
    filter: grayscale(80%);
  }

  &__desc {
    @include flex-align(center);
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.003em;
    color: $pink;
    height: 34px;
    margin-bottom: 0;
  }

  .nftCards__name,
  .nftCards__collectionInner {
    color: var(--theme-text-gray);
  }
}

.claimCard {
  height: 500px;
  min-height: 399px;

  @include media-tablet {
    height: 470px;
    min-height: 357px;
  }

  @include media-mobile {
    height: 405px;
    min-height: 305px;
  }

  // .nftCards__info {
  //   height: 245px;

  //   @include media-tablet {
  //     height: 225px;
  //   }

  //   @include media-mobile {
  //     height: 215px;
  //     padding-bottom: 15px;
  //   }
  // }

  .nftCards__image {
    height: 254px;

    @include media-tablet {
      height: 238px;
    }

    @include media-mobile {
      height: 179px;
    }
  }
}
